.ant-layout-header {
    background-color: #ffffff;
}

.ant-layout-sider,
.ant-menu-submenu {
    background-color: #1e513c !important;
}

.ant-btn-primary {
    color: #000000 !important;
}

.ant-btn-link {
    color: #000000;
}

.ant-form-item {
    margin-bottom: 15px;
}

.ant-table-expanded-row .ant-table-thead > tr > th {
    background: #f6fefa;
}

.ant-table-expanded-row .ant-table-cell {
    background-color: #ffffff;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
    background-color: #ffffff;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
}

.ant-input[disabled],
.ant-input-outlined.ant-input-disabled {
    color: #000000;
}

.ant-layout {
    background-color: #ffffff;
}

.ant-pagination-item:hover a,
.ant-pagination-item-link:hover,
.ant-btn-link:hover {
    color: #74a3c7 !important;
}

.ant-pagination-item-active a {
    color: #74a3c7 !important;
}

.ant-btn-default:hover {
    border-color: #74a3c7 !important;
    color: #74a3c7 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
    background-color: #1e513c !important;
}
.ant-select .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.ant-layout-sider {
    height: 100vh;
    min-height: 100vh;
}

.ant-notification-notice-description {
    margin-left: 0 !important;
}

.ant-input-group-wrapper-disabled .ant-input-group-addon,
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selector {
    color: #000000 !important;
}

.ant-menu-horizontal {
    justify-content: right;
}

.ant-steps-item-title {
    font-size: 12px !important;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: var(--primary-color);
}

.ant-layout .ant-layout-sider-children {
    height: calc(100vh - 48px) !important;
    overflow: auto;
}

.ant-layout .ant-layout-sider-children::-webkit-scrollbar {
    width: 0px; /* Chrome & Edge */
}
/* .rc-virtual-list-scrollbar,
.ant-layout-sider-children::-webkit-scrollbar {
    width: 0.45em !important;
    height: 0.45em !important;
} */

::-webkit-scrollbar-thumb,
.rc-virtual-list-scrollbar-thumb,
.ant-layout-sider-children::-webkit-scrollbar-thumb,
.ant-table-tbody-virtual-scrollbar-thumb {
    background-color: var(--primary-color) !important;
    border-radius: 0px !important;
}

::-webkit-scrollbar-thumb:horizontal,
.main-content::-webkit-scrollbar-thumb:vertical,
.ant-table-tbody-virtual-scrollbar-thumb {
    background-color: var(--primary-color) !important;
    border-radius: 0px !important;
}

.ant-table-tbody-virtual-scrollbar,
.ant-table-tbody-virtual-scrollbar-vertical {
    width: 16px !important;
    background-color: #f0f0f0;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-first-item).ant-input-number-compact-last-item,
.ant-select-compact-item:not(.ant-select-compact-first-item).ant-select-compact-last-item > .ant-select-selector {
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
}

.ant-layout-sider-trigger {
    background: #0e3324 !important;
}

.ant-divider-horizontal.ant-divider-with-text {
    color: #ffffff;
    background: #34624f;
    padding-bottom: 2px;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 5px;
}

.ant-tag {
    text-wrap: balance;
}

.ant-tabs-tab-btn {
    font-size: 18px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--main-green) !important;
    font-weight: 500;
}

.ant-tabs .ant-tabs-ink-bar {
    background: var(--main-green) !important;
}

.ant-table-wrapper:only-child .ant-table {
    margin-inline: 24px -8px !important;
}

.ant-table-summary {
    background-color: #fafafa !important;
    font-weight: 700;
}
