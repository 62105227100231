.PhoneInputInput {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
}

.PhoneInputInput:hover {
    border-color: #ffe957;
}

input:focus-visible {
    outline: none;
}

.PhoneInputInput:focus {
    border-color: #ffdd2d;
    box-shadow: 0 0 0 2px rgba(255, 255, 5, 0.06);
}
